import { DefaultRoute } from '../router/routes'

export const datetimeFormat = { 
  month: 'short', 
  day: 'numeric', 
  year: 'numeric', 
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZone: "CET",
  timeZoneName: "short", 
}

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

export const formatMoney = m => {
  if (!m) {
    return null
  }
  const val = m.value * Math.pow(10, m.exponent)
  const cur = (m.currencyName ?? '').replace('currencies/', '').toLowerCase()
  const locale = val.toLocaleString(undefined, { minimumFractionDigits: 0 })
  if (cur === 'usd') {
    return locale.startsWith("-") ? `-$${locale.substring(1)}` : `$${locale}`
  } else if (cur === 'eur') {
    return locale.startsWith("-") ? `-€${locale.substring(1)}` : `€${locale}`
  } else if (cur === 'amd') {
    return locale.startsWith("-") ? `-֏${locale.substring(1)} amd` : `֏${locale}`
  } else {
    return `${locale} ${cur?.toUpperCase()}`
  }
}

export const compareJSON = (obj1, obj2) => {
  let differences = {};

  // Helper function to recursively compare objects
  function findDifferences(keyPath, value1, value2) {
      if (typeof value1 === 'object' && value1 !== null && !Array.isArray(value1)) {
          // If both values are objects, recurse deeper
          let objDiff = {};
          for (let key in value1) {
              if (value1.hasOwnProperty(key)) {
                  const nestedDiff = findDifferences([...keyPath, key], value1[key], value2[key]);
                  if (Object.keys(nestedDiff).length > 0) {
                      objDiff[key] = nestedDiff;
                  }
              }
          }
          return objDiff;
      } else if (Array.isArray(value1)) {
          // Compare arrays
          if (value1.length !== value2.length) {
              return value1;
          }
          let arrDiff = value1.map((item, index) => findDifferences([...keyPath, index], item, value2[index]));
          return arrDiff.filter(diff => Object.keys(diff).length > 0).length > 0 ? arrDiff : {};
      } else {
          // If values are not equal, return the difference
          if (value1 !== value2) {
              return { old: value1, new: value2 };
          }
      }
      return {};
  }

  const keys = Array.from(new Set([].concat(Object.keys(obj1 ?? {})).concat(Object.keys(obj2 ?? {}))))

  for (const key of keys) {
      const diff = findDifferences([key], (obj1 ?? {})[key], (obj2 ?? {})[key]);
      if (Object.keys(diff).length > 0) {
          differences[key] = diff;
      }
  }

  return differences;
}

export const currencySymbol = m => {
  const cur = (m?.currencyName ?? '').replace('currencies/', '')
  if (cur === 'usd')
    return '$'
  else if (cur === 'eur')
    return '€'
  else if (cur === 'amd')
    return '֏'
  else
    return cur?.toUpperCase()
}

export const defaultMoney = () => ({
  currencyName: 'currencies/eur',
  exponent: -2
})

export const formatMeasure = m => {
  if (!m) {
    return null
  }
  const val = m.value * Math.pow(10, m.exponent)
  const locale = val.toLocaleString(undefined, { minimumFractionDigits: 0 })
  return `${locale} ${measureSymbol(m)}`
}

export const measureSymbol = m => {
  const unt = (m?.unitName ?? '').replace('units/', '')
  if (unt === "m2") {
    return "㎡"
  }
  return unt?.toLowerCase()
}

export const defaultWeightMeasure = () => ({
  unitName: 'units/kg',
  exponent: -1
})

export const defaultLengthMeasure = () => ({
  unitName: 'units/cm',
  exponent: 0
})

export const defaultQuantityMeasure = () => ({
  unitName: 'units/pcs',
  exponent: 0
})

export const createMoney = (value, money) => {
  return {
    ...money,
    value: value * Math.pow(10, -money.exponent)
  }
}

export const moneyValue = m => {
  if (!m) {
    return null
  }
  return m.value * Math.pow(10, m.exponent)
}

export const createMeasure = (value, measure) => {
  return {
    ...measure,
    value: value * Math.pow(10, -measure.exponent) | 0
  }
}

export const parseMoneyValue = (value, money) => {
  if (!value) {
    return [createMoney(0, money), null]
  }
  let formatted = value.replace(',', '.').replace(/[^\d.-]/g, '')
  if (formatted.indexOf('.') !== formatted.lastIndexOf('.')) {
    formatted = formatted.substring(0, formatted.indexOf('.', formatted.indexOf('.') + 1))
  }
  if (formatted.indexOf('.') >= 0 && formatted.length > formatted.indexOf('.') - money.exponent + 1) {
    formatted = formatted.substring(0, formatted.indexOf('.') - money.exponent + 1)
  }
  if (formatted && formatted.length > 1 && formatted[0] === '0') {
    formatted = formatted.substring(1)
  }
  return [
    createMoney(parseFloat(formatted), money),
    !!formatted ? formatted : null
  ]
}

export const metricLengthOptions = [
  {
    value: "units/m",
    label: "m"
  },
  {
    value: "units/ft",
    label: "ft"
  }
]

export const metricSquareOptions = [
  {
    value: "units/m2",
    label: "㎡"
  },
  {
    value: "units/ft2",
    label: "ft2"
  }
]

export const measureValue = m => {
  if (!m) {
    return null
  }
  return m.value * Math.pow(10, m.exponent)
}

export const formatMeasures = (arr) => {
  return arr.map(e => measureValue(e)).filter(e => e).reduce((a, e) => a + (a ? 'x' : '') + e, '')
}

export const dateToTimestamp = v => {
  if (!v)
    return null
  let date = new Date(v)
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  date = new Date(date.getTime() - userTimezoneOffset)
  const time = date.getTime()

  return { seconds: time / 1000, nanos: time % 1000 * 1e6 }
}

export const timestampToDate = v => {
  if (!v)
    return null
  var d = new Date(v.seconds * 1000)
  d.setMilliseconds(v.nanos / 1e6)
  return d
}

export const dateToProto = v => {
  if (!v)
    return null
  let date = new Date(v)

  return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
}

export const protoToDate = v => {
  if (!v)
    return null
  return new Date(v.year, v.month - 1, v.day)
}

export const timestampToProto = v => {
  if (!v)
    return null
  const dtm = timestampToDate(v)
  const proto = window.proto
  const date = new proto.google.protobuf.Timestamp()
  date.fromDate(dtm)
  return date
}

export const formatTimestamp = v => {
  const date = timestampToDate(v)
  if (!date)
    return "-"
  return date.toLocaleDateString()
}

export const formatDateTimeTimestamp = v => {
  const date = timestampToDate(v)
  if (!date)
    return "-"
  return date.toLocaleDateString() + " " + date.toLocaleTimeString()
}

export const dateToIso = v => {
  if (!v)
    return null
  try {
    let date = new Date(v)
    const userTimezoneOffset = date.getTimezoneOffset() * 60000
    date = new Date(date.getTime() - userTimezoneOffset)
    return new Date(date).toISOString().substring(0, 10)
  }
  catch {
    return null
  }
}

export const formatUsername = username => {
  if (!username)
    return null
  return username.split('_').map(e => e.substring(0, 1).toUpperCase() + e.substring(1).toLowerCase()).reduce((a, e) => a + (a ? ' ' : '') + e, '')
}

export const toMap = protoMap => {
  if (!protoMap) {
    return {}
  }
  const result = {}
  for (const i in protoMap) {
    result[protoMap[i][0]] = protoMap[i][1]
  }
  return result
}

export const formatMap = protoMap => {
  if (!protoMap) {
    return {}
  }
  const map = toMap(protoMap)

  let result = []
  for (const key in map) {
    result.push(`${key}: ${map[key]}`)
    result.push(<br/>)
  }
  if (result.length > 0) {
    result.splice(result.length - 1, 1)
  }

  return result
}

export const formatMapValue = protoMap => {
  if (!protoMap) {
    return {}
  }
  const map = toMap(protoMap)

  let result = []
  for (const key in map) {
    result.push(`${key}: ${map[key]}`)
  }

  return result.reduce((a, e) => a + (a ? '\r\n' : '') + e, '')
}

export const mapToProto = v => {
  const result = []
  for (const key in v) {
    result.push([key, v[key]])
  }
  return result
}

export function getDateXDaysAgo(eventDate, date = new Date()) {
  if (!eventDate)
    return "-"

  let difference = date.getTime() - eventDate.getTime()
  if (difference < 0)
    return null
  let days = Math.ceil(difference / (1000 * 3600 * 24))
  if (days > 0)
    return `${days} days ago`

  const hours = Math.ceil(difference / (1000 * 3600))
  if (hours > 0)
    return `${hours} hours ago`

  const mins = Math.ceil(difference / (1000 * 60))
  if (mins > 0)
    return `${mins} mins ago`
  
  return 'now';
}

export function isTimestampPassed(ts, date = new Date()) {
  if (!ts)
    return false;  
  const eventDate = timestampToDate(ts)
  return eventDate.getTime() < date.getTime()
}

export function getTimestampDaysAgo(ts, date = new Date()) {
  const eventDate = timestampToDate(ts)
  return getDateXDaysAgo(eventDate, date)
}

export function getIsoDaysAgo(d, date = new Date()) {
  if (!d)
    return "-"
  const eventDate = new Date(d)
  return getDateXDaysAgo(eventDate, date)
}

export const today = () => {
  var today = new Date()
  var dd = String(today.getDate()).padStart(2, '0')
  var mm = String(today.getMonth() + 1).padStart(2, '0')
  var yyyy = today.getFullYear()

  return new Date(yyyy + '-' + mm + '-' + dd + 'T00:00:00')
}

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/** Splits a camel-case or Pascal-case variable name into individual words.
 * @param {string} s
 * @returns {string[]}
 */
export const splitCamelCaseWords = (s) => {
  var re, match, output = [];
  // re = /[A-Z]?[a-z]+/g
  re = /([A-Za-z]?)([a-z]+)/g;

  /*
  matches example: "oneTwoThree"
  ["one", "o", "ne"]
  ["Two", "T", "wo"]
  ["Three", "T", "hree"]
  */

  match = re.exec(s);
  while (match) {
    // output.push(match.join(""));
    output.push([match[1].toUpperCase(), match[2]].join(""));
    match = re.exec(s);
  }

  return output;
}

/** Splits a snake-case variable name into individual words.
 * @param {string} s
 * @returns {string[]}
 */
export const splitSnakeCaseWords = (s) => {
  return s.split('_').map(e => e[0].toUpperCase() + e.substring(1))
}

const DESCRIPTION_DELIMITER = "--"
export const tryParseCustomerExtId = description => {
  if (!description)
    return null
  const ind = description.lastIndexOf(DESCRIPTION_DELIMITER)
  if (ind === -1)
    return null
  const prefix = description.substring(ind + DESCRIPTION_DELIMITER.length).trim()
  return prefix.toLowerCase()
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, format= 'en-US', formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value

  // timestamp format
  if (value?.seconds) {
    value = timestampToDate(value)
  }

  return new Intl.DateTimeFormat(format, formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTime = undefined) => {
  if (!value) return value

  // timestamp format
  if (value?.seconds) {
    value = timestampToDate(value)
  }
  
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTime === undefined && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  if (toTime === true) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return DefaultRoute
  if (userRole === 'agent') return DefaultRoute
  if (userRole === 'client') return '/access-control'
  return DefaultRoute
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export function downloadFile(url, filename) {
  // Create a new anchor element
  const a = document.createElement('a')
  a.href = url
  a.download = filename // Specify the name of the file to download
  a.target='_blank' 
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a) // Remove the anchor element after the download is triggered
}
