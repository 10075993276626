// source: projects/erp/service/api/v1/model/customer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var projects_erp_service_api_v1_model_date_pb = require('../date_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_date_pb);
var projects_erp_service_api_v1_model_list_of_strings_pb = require('../list_of_strings_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_list_of_strings_pb);
var projects_erp_service_api_v1_model_money_pb = require('../money_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_money_pb);
goog.exportSymbol('proto.dating.erp.api.v1.model.Customer', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.model.Customer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dating.erp.api.v1.model.Customer.repeatedFields_, null);
};
goog.inherits(proto.dating.erp.api.v1.model.Customer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.model.Customer.displayName = 'proto.dating.erp.api.v1.model.Customer';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dating.erp.api.v1.model.Customer.repeatedFields_ = [32,33];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.model.Customer.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.model.Customer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.model.Customer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.model.Customer.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    number: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstName: (f = msg.getFirstName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    middleName: (f = msg.getMiddleName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastName: (f = msg.getLastName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    zip: (f = msg.getZip()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    countryName: (f = msg.getCountryName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    stateName: (f = msg.getStateName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    cityName: (f = msg.getCityName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    cityDisplayName: (f = msg.getCityDisplayName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    street: (f = msg.getStreet()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    buildingNumber: (f = msg.getBuildingNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    mobile: (f = msg.getMobile()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    login: (f = msg.getLogin()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    registrationIp: (f = msg.getRegistrationIp()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    dateOfBirth: (f = msg.getDateOfBirth()) && projects_erp_service_api_v1_model_date_pb.Date.toObject(includeInstance, f),
    productName: (f = msg.getProductName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    subscriptionName: (f = msg.getSubscriptionName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    dateOfSubscription: (f = msg.getDateOfSubscription()) && projects_erp_service_api_v1_model_date_pb.Date.toObject(includeInstance, f),
    sex: (f = msg.getSex()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lookingFor: (f = msg.getLookingFor()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    os: (f = msg.getOs()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    browser: (f = msg.getBrowser()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 28, ""),
    selfieUrl: (f = msg.getSelfieUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    photoUrlsMap: (f = msg.getPhotoUrlsMap()) ? f.toObject(includeInstance, proto.dating.erp.api.v1.model.ListOfStrings.toObject) : [],
    isInternal: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    categoryValuesList: (f = jspb.Message.getRepeatedField(msg, 32)) == null ? undefined : f,
    lookingCategoryValuesList: (f = jspb.Message.getRepeatedField(msg, 33)) == null ? undefined : f,
    valuesMap: (f = msg.getValuesMap()) ? f.toObject(includeInstance, undefined) : [],
    username: (f = msg.getUsername()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isModerated: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    isMobileVerified: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    isEmailVerified: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    isMobileConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
    isEmailConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    userId: (f = msg.getUserId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    clickId: (f = msg.getClickId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    landingPage: (f = msg.getLandingPage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    campaign: (f = msg.getCampaign()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    source: (f = msg.getSource()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    subSource: (f = msg.getSubSource()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    registrationUserAgent: jspb.Message.getFieldWithDefault(msg, 50, ""),
    balance: (f = msg.getBalance()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    customerActionStatusName: jspb.Message.getFieldWithDefault(msg, 52, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.model.Customer}
 */
proto.dating.erp.api.v1.model.Customer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.model.Customer;
  return proto.dating.erp.api.v1.model.Customer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.model.Customer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.model.Customer}
 */
proto.dating.erp.api.v1.model.Customer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstName(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddleName(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastName(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setZip(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCountryName(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStateName(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCityName(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCityDisplayName(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStreet(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuildingNumber(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMobile(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLogin(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRegistrationIp(value);
      break;
    case 19:
      var value = new projects_erp_service_api_v1_model_date_pb.Date;
      reader.readMessage(value,projects_erp_service_api_v1_model_date_pb.Date.deserializeBinaryFromReader);
      msg.setDateOfBirth(value);
      break;
    case 20:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setProductName(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSubscriptionName(value);
      break;
    case 22:
      var value = new projects_erp_service_api_v1_model_date_pb.Date;
      reader.readMessage(value,projects_erp_service_api_v1_model_date_pb.Date.deserializeBinaryFromReader);
      msg.setDateOfSubscription(value);
      break;
    case 23:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSex(value);
      break;
    case 24:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLookingFor(value);
      break;
    case 25:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 26:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOs(value);
      break;
    case 27:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBrowser(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 29:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSelfieUrl(value);
      break;
    case 30:
      var value = msg.getPhotoUrlsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.dating.erp.api.v1.model.ListOfStrings.deserializeBinaryFromReader, "", new proto.dating.erp.api.v1.model.ListOfStrings());
         });
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInternal(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryValues(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.addLookingCategoryValues(value);
      break;
    case 34:
      var value = msg.getValuesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 35:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUsername(value);
      break;
    case 36:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 37:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 38:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsModerated(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMobileVerified(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEmailVerified(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMobileConfirmed(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEmailConfirmed(value);
      break;
    case 44:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 45:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClickId(value);
      break;
    case 46:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLandingPage(value);
      break;
    case 47:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCampaign(value);
      break;
    case 48:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 49:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSubSource(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationUserAgent(value);
      break;
    case 51:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setBalance(value);
      break;
    case 52:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerActionStatusName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.model.Customer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.model.Customer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.model.Customer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.model.Customer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMiddleName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastName();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getZip();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCountryName();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStateName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCityName();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCityDisplayName();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStreet();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBuildingNumber();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMobile();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLogin();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationIp();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDateOfBirth();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      projects_erp_service_api_v1_model_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionName();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDateOfSubscription();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      projects_erp_service_api_v1_model_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSex();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLookingFor();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOs();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBrowser();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getSelfieUrl();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhotoUrlsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(30, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.dating.erp.api.v1.model.ListOfStrings.serializeBinaryToWriter);
  }
  f = message.getIsInternal();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getCategoryValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      32,
      f
    );
  }
  f = message.getLookingCategoryValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      33,
      f
    );
  }
  f = message.getValuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(34, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getUsername();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsModerated();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getIsMobileVerified();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getIsEmailVerified();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getIsMobileConfirmed();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getIsEmailConfirmed();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClickId();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLandingPage();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCampaign();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSubSource();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationUserAgent();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getBalance();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getCustomerActionStatusName();
  if (f.length > 0) {
    writer.writeString(
      52,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string number = 4;
 * @return {string}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue first_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getFirstName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setFirstName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearFirstName = function() {
  return this.setFirstName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue middle_name = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getMiddleName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setMiddleName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearMiddleName = function() {
  return this.setMiddleName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasMiddleName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue last_name = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getLastName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setLastName = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearLastName = function() {
  return this.setLastName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue zip = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getZip = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setZip = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearZip = function() {
  return this.setZip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasZip = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue country_name = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getCountryName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setCountryName = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearCountryName = function() {
  return this.setCountryName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasCountryName = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue state_name = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getStateName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setStateName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearStateName = function() {
  return this.setStateName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasStateName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue city_name = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getCityName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setCityName = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearCityName = function() {
  return this.setCityName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasCityName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue city_display_name = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getCityDisplayName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setCityDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearCityDisplayName = function() {
  return this.setCityDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasCityDisplayName = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue street = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getStreet = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setStreet = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearStreet = function() {
  return this.setStreet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasStreet = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.StringValue building_number = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getBuildingNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setBuildingNumber = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearBuildingNumber = function() {
  return this.setBuildingNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasBuildingNumber = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.StringValue email = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue mobile = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getMobile = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setMobile = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearMobile = function() {
  return this.setMobile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasMobile = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue login = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getLogin = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setLogin = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearLogin = function() {
  return this.setLogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasLogin = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.StringValue registration_ip = 18;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getRegistrationIp = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 18));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setRegistrationIp = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearRegistrationIp = function() {
  return this.setRegistrationIp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasRegistrationIp = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional Date date_of_birth = 19;
 * @return {?proto.dating.erp.api.v1.model.Date}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getDateOfBirth = function() {
  return /** @type{?proto.dating.erp.api.v1.model.Date} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_date_pb.Date, 19));
};


/**
 * @param {?proto.dating.erp.api.v1.model.Date|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setDateOfBirth = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearDateOfBirth = function() {
  return this.setDateOfBirth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasDateOfBirth = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.StringValue product_name = 20;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getProductName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 20));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setProductName = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearProductName = function() {
  return this.setProductName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasProductName = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.StringValue subscription_name = 21;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getSubscriptionName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 21));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setSubscriptionName = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearSubscriptionName = function() {
  return this.setSubscriptionName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasSubscriptionName = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional Date date_of_subscription = 22;
 * @return {?proto.dating.erp.api.v1.model.Date}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getDateOfSubscription = function() {
  return /** @type{?proto.dating.erp.api.v1.model.Date} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_date_pb.Date, 22));
};


/**
 * @param {?proto.dating.erp.api.v1.model.Date|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setDateOfSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearDateOfSubscription = function() {
  return this.setDateOfSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasDateOfSubscription = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.StringValue sex = 23;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getSex = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 23));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setSex = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearSex = function() {
  return this.setSex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasSex = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.StringValue looking_for = 24;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getLookingFor = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 24));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setLookingFor = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearLookingFor = function() {
  return this.setLookingFor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasLookingFor = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.protobuf.StringValue device = 25;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getDevice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 25));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.protobuf.StringValue os = 26;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getOs = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 26));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setOs = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearOs = function() {
  return this.setOs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasOs = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.protobuf.StringValue browser = 27;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getBrowser = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 27));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setBrowser = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearBrowser = function() {
  return this.setBrowser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasBrowser = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional string status = 28;
 * @return {string}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional google.protobuf.StringValue selfie_url = 29;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getSelfieUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 29));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setSelfieUrl = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearSelfieUrl = function() {
  return this.setSelfieUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasSelfieUrl = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * map<string, ListOfStrings> photo_urls = 30;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.dating.erp.api.v1.model.ListOfStrings>}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getPhotoUrlsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.dating.erp.api.v1.model.ListOfStrings>} */ (
      jspb.Message.getMapField(this, 30, opt_noLazyCreate,
      proto.dating.erp.api.v1.model.ListOfStrings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearPhotoUrlsMap = function() {
  this.getPhotoUrlsMap().clear();
  return this;};


/**
 * optional bool is_internal = 31;
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getIsInternal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setIsInternal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * repeated string category_values = 32;
 * @return {!Array<string>}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getCategoryValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 32));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setCategoryValuesList = function(value) {
  return jspb.Message.setField(this, 32, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.addCategoryValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 32, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearCategoryValuesList = function() {
  return this.setCategoryValuesList([]);
};


/**
 * repeated string looking_category_values = 33;
 * @return {!Array<string>}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getLookingCategoryValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 33));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setLookingCategoryValuesList = function(value) {
  return jspb.Message.setField(this, 33, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.addLookingCategoryValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 33, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearLookingCategoryValuesList = function() {
  return this.setLookingCategoryValuesList([]);
};


/**
 * map<string, string> values = 34;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getValuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 34, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearValuesMap = function() {
  this.getValuesMap().clear();
  return this;};


/**
 * optional google.protobuf.StringValue username = 35;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getUsername = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 35));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setUsername = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearUsername = function() {
  return this.setUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional google.protobuf.StringValue description = 36;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 36));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 37;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 37));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 38;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 38));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional bool is_moderated = 39;
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getIsModerated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setIsModerated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional bool is_mobile_verified = 40;
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getIsMobileVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setIsMobileVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional bool is_email_verified = 41;
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getIsEmailVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setIsEmailVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool is_mobile_confirmed = 42;
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getIsMobileConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setIsMobileConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool is_email_confirmed = 43;
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getIsEmailConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setIsEmailConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional google.protobuf.StringValue user_id = 44;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getUserId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 44));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional google.protobuf.StringValue click_id = 45;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getClickId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 45));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setClickId = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearClickId = function() {
  return this.setClickId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasClickId = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional google.protobuf.StringValue landing_page = 46;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getLandingPage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 46));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setLandingPage = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearLandingPage = function() {
  return this.setLandingPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasLandingPage = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional google.protobuf.StringValue campaign = 47;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getCampaign = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 47));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setCampaign = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearCampaign = function() {
  return this.setCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional google.protobuf.StringValue source = 48;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getSource = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 48));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 48, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasSource = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional google.protobuf.StringValue sub_source = 49;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getSubSource = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 49));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setSubSource = function(value) {
  return jspb.Message.setWrapperField(this, 49, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearSubSource = function() {
  return this.setSubSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasSubSource = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional string registration_user_agent = 50;
 * @return {string}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getRegistrationUserAgent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setRegistrationUserAgent = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional Money balance = 51;
 * @return {?proto.dating.erp.api.v1.model.Money}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getBalance = function() {
  return /** @type{?proto.dating.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 51));
};


/**
 * @param {?proto.dating.erp.api.v1.model.Money|undefined} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
*/
proto.dating.erp.api.v1.model.Customer.prototype.setBalance = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.clearBalance = function() {
  return this.setBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.model.Customer.prototype.hasBalance = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional string customer_action_status_name = 52;
 * @return {string}
 */
proto.dating.erp.api.v1.model.Customer.prototype.getCustomerActionStatusName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 52, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.model.Customer} returns this
 */
proto.dating.erp.api.v1.model.Customer.prototype.setCustomerActionStatusName = function(value) {
  return jspb.Message.setProto3StringField(this, 52, value);
};


goog.object.extend(exports, proto.dating.erp.api.v1.model);
