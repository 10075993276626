import { ApiUrl, getMetadata } from '../index'
import {
  ListFinancialOperationsRequest
} from './financial_operations_pb'
import { FinancialOperationsClient } from './financial_operations_grpc_web_pb'

let client = new FinancialOperationsClient(ApiUrl, null, null);

const toFinancialOperationObject = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount") {
      result[key] = instance[key]
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listFinancialOperations: (params) => {
    const request = new ListFinancialOperationsRequest()
    if (params?.customerName) {
      request.setCustomerName(params?.customerName ?? "")
    }

    return new Promise((resolve, reject) => {
      client.listFinancialOperations(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            financialOperationsList: result.financialOperationsList.map(toFinancialOperationObject)
          })
        }
      })
    })
  }
};

export default API;
