import { ApiUrl, getMetadata } from '../index'
import { TriggerInstance } from './trigger_instance_pb'
import {
  GetTriggerInstanceRequest,
  ListTriggerInstancesRequest,
  ApplyTriggerInstanceRequest,
  DeleteTriggerInstanceRequest,
  ExecuteTriggerInstanceRequest
} from './trigger_instances_pb'
import { TriggerInstancesClient } from './trigger_instances_grpc_web_pb'

let client = new TriggerInstancesClient(ApiUrl, null, null);

const toTriggerInstanceModel = (instance) => {
  let result = new TriggerInstance()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setProductName(instance.productName)
  // todo

  return result
}

const API = {
  listTriggerInstances: (params) => {
    const request = new ListTriggerInstancesRequest()
    if (params?.customerName) {
      request.setCustomerName(params.customerName)
    }
    return new Promise((resolve, reject) => {
      client.listTriggerInstances(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getTriggerInstance: (name) => {
    const request = new GetTriggerInstanceRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.getTriggerInstance(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyTriggerInstance: (instance) => {
    const applying = toTriggerInstanceModel(instance)
    const request = new ApplyTriggerInstanceRequest();
    request.setTriggerInstance(applying)
    return new Promise((resolve, reject) => {
      client.applyTriggerInstance(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  executeTriggerInstance: (name) => {
    const request = new ExecuteTriggerInstanceRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.executeTriggerInstance(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  deleteTriggerInstance: (name) => {
    const request = new DeleteTriggerInstanceRequest()
    request.setName(name)
    return new Promise((resolve, reject) => {
      client.deleteTriggerInstance(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
          reject(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
