/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/financial_operations.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var projects_erp_service_api_v1_model_financial_operation_pb = require('./financial_operation_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./financial_operations_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.FinancialOperationsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.FinancialOperationsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListFinancialOperationsRequest,
 *   !proto.dating.erp.api.v1.services.ListFinancialOperationsResponse>}
 */
const methodDescriptor_FinancialOperations_ListFinancialOperations = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.FinancialOperations/ListFinancialOperations',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListFinancialOperationsRequest,
  proto.dating.erp.api.v1.services.ListFinancialOperationsResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListFinancialOperationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListFinancialOperationsResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListFinancialOperationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListFinancialOperationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListFinancialOperationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.FinancialOperationsClient.prototype.listFinancialOperations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.FinancialOperations/ListFinancialOperations',
      request,
      metadata || {},
      methodDescriptor_FinancialOperations_ListFinancialOperations,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListFinancialOperationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListFinancialOperationsResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.FinancialOperationsPromiseClient.prototype.listFinancialOperations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.FinancialOperations/ListFinancialOperations',
      request,
      metadata || {},
      methodDescriptor_FinancialOperations_ListFinancialOperations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetFinancialOperationRequest,
 *   !proto.dating.erp.api.v1.model.FinancialOperation>}
 */
const methodDescriptor_FinancialOperations_GetFinancialOperation = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.FinancialOperations/GetFinancialOperation',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetFinancialOperationRequest,
  projects_erp_service_api_v1_model_financial_operation_pb.FinancialOperation,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetFinancialOperationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_financial_operation_pb.FinancialOperation.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetFinancialOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.FinancialOperation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.FinancialOperation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.FinancialOperationsClient.prototype.getFinancialOperation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.FinancialOperations/GetFinancialOperation',
      request,
      metadata || {},
      methodDescriptor_FinancialOperations_GetFinancialOperation,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetFinancialOperationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.FinancialOperation>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.FinancialOperationsPromiseClient.prototype.getFinancialOperation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.FinancialOperations/GetFinancialOperation',
      request,
      metadata || {},
      methodDescriptor_FinancialOperations_GetFinancialOperation);
};


module.exports = proto.dating.erp.api.v1.services;

