import { ApiUrl, getMetadata } from '../index'
import { CustomerActionStatus } from './customer_action_status_pb'
import {
  GetCustomerActionStatusRequest,
  ListCustomerActionStatusesRequest,
  ApplyCustomerActionStatusRequest
} from './customer_action_statuses_pb'
import { CustomerActionStatusesClient } from './customer_action_statuses_grpc_web_pb'

let customerActionStatusesClient = new CustomerActionStatusesClient(ApiUrl, null, null);

const customerActionStatus2Model = (instance) => {
  let result = new CustomerActionStatus()
  if (instance.name)
    result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  result.setCondition(instance.condition)
  result.setActionCode(instance.actionCode)
  result.setOrderIndex(instance.orderIndex)
  return result
}

const API = {
  listCustomerActionStatuses: () => {
    const request = new ListCustomerActionStatusesRequest();
    return new Promise((resolve) => {
      customerActionStatusesClient.listCustomerActionStatuses(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject().customerActionStatusesList)
        }
      });
    });
  },
  getCustomerActionStatus: (name) => {
    const request = new GetCustomerActionStatusRequest()
    request.setName(name)
    return new Promise((resolve) => {
      customerActionStatusesClient.getCustomerActionStatus(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyCustomerActionStatus: (instance) => {
    const applying = customerActionStatus2Model(instance)
    const request = new ApplyCustomerActionStatusRequest();
    request.setCustomerActionStatus(applying)
    return new Promise((resolve) => {
      customerActionStatusesClient.applyCustomerActionStatus(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  }
};

export default API;
