// source: projects/erp/service/api/v1/services/trigger_instances.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var projects_erp_service_api_v1_model_trigger_instance_pb = require('./trigger_instance_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_trigger_instance_pb);
goog.exportSymbol('proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.GetTriggerInstanceRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.ListTriggerInstancesRequest', null, global);
goog.exportSymbol('proto.dating.erp.api.v1.services.ListTriggerInstancesResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.ListTriggerInstancesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.displayName = 'proto.dating.erp.api.v1.services.ListTriggerInstancesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.repeatedFields_, null);
};
goog.inherits(proto.dating.erp.api.v1.services.ListTriggerInstancesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.displayName = 'proto.dating.erp.api.v1.services.ListTriggerInstancesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.GetTriggerInstanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.GetTriggerInstanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.displayName = 'proto.dating.erp.api.v1.services.GetTriggerInstanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.displayName = 'proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.displayName = 'proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.displayName = 'proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.ListTriggerInstancesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.ListTriggerInstancesRequest}
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.ListTriggerInstancesRequest;
  return proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.ListTriggerInstancesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.ListTriggerInstancesRequest}
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.ListTriggerInstancesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_name = 1;
 * @return {string}
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.services.ListTriggerInstancesRequest} returns this
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesRequest.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.ListTriggerInstancesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    triggerinstancesList: jspb.Message.toObjectList(msg.getTriggerinstancesList(),
    projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.ListTriggerInstancesResponse}
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.ListTriggerInstancesResponse;
  return proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.ListTriggerInstancesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.ListTriggerInstancesResponse}
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance;
      reader.readMessage(value,projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance.deserializeBinaryFromReader);
      msg.addTriggerinstances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.ListTriggerInstancesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTriggerinstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance.serializeBinaryToWriter
    );
  }
};


/**
 * repeated dating.erp.api.v1.model.TriggerInstance triggerInstances = 1;
 * @return {!Array<!proto.dating.erp.api.v1.model.TriggerInstance>}
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.prototype.getTriggerinstancesList = function() {
  return /** @type{!Array<!proto.dating.erp.api.v1.model.TriggerInstance>} */ (
    jspb.Message.getRepeatedWrapperField(this, projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance, 1));
};


/**
 * @param {!Array<!proto.dating.erp.api.v1.model.TriggerInstance>} value
 * @return {!proto.dating.erp.api.v1.services.ListTriggerInstancesResponse} returns this
*/
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.prototype.setTriggerinstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dating.erp.api.v1.model.TriggerInstance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dating.erp.api.v1.model.TriggerInstance}
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.prototype.addTriggerinstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dating.erp.api.v1.model.TriggerInstance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dating.erp.api.v1.services.ListTriggerInstancesResponse} returns this
 */
proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.prototype.clearTriggerinstancesList = function() {
  return this.setTriggerinstancesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.GetTriggerInstanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.GetTriggerInstanceRequest}
 */
proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.GetTriggerInstanceRequest;
  return proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.GetTriggerInstanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.GetTriggerInstanceRequest}
 */
proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.GetTriggerInstanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.services.GetTriggerInstanceRequest} returns this
 */
proto.dating.erp.api.v1.services.GetTriggerInstanceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    triggerinstance: (f = msg.getTriggerinstance()) && projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest}
 */
proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest;
  return proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest}
 */
proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance;
      reader.readMessage(value,projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance.deserializeBinaryFromReader);
      msg.setTriggerinstance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTriggerinstance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance.serializeBinaryToWriter
    );
  }
};


/**
 * optional dating.erp.api.v1.model.TriggerInstance triggerInstance = 1;
 * @return {?proto.dating.erp.api.v1.model.TriggerInstance}
 */
proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.prototype.getTriggerinstance = function() {
  return /** @type{?proto.dating.erp.api.v1.model.TriggerInstance} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance, 1));
};


/**
 * @param {?proto.dating.erp.api.v1.model.TriggerInstance|undefined} value
 * @return {!proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest} returns this
*/
proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.prototype.setTriggerinstance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest} returns this
 */
proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.prototype.clearTriggerinstance = function() {
  return this.setTriggerinstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest.prototype.hasTriggerinstance = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest}
 */
proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest;
  return proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest}
 */
proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest} returns this
 */
proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest}
 */
proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest;
  return proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest}
 */
proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest} returns this
 */
proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.dating.erp.api.v1.services);
