/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/customer_action_statuses.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var projects_erp_service_api_v1_model_customer_action_status_pb = require('./customer_action_status_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./customer_action_statuses_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CustomerActionStatusesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.CustomerActionStatusesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListCustomerActionStatusesRequest,
 *   !proto.dating.erp.api.v1.services.ListCustomerActionStatusesResponse>}
 */
const methodDescriptor_CustomerActionStatuses_ListCustomerActionStatuses = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CustomerActionStatuses/ListCustomerActionStatuses',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListCustomerActionStatusesRequest,
  proto.dating.erp.api.v1.services.ListCustomerActionStatusesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListCustomerActionStatusesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListCustomerActionStatusesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListCustomerActionStatusesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListCustomerActionStatusesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListCustomerActionStatusesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CustomerActionStatusesClient.prototype.listCustomerActionStatuses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerActionStatuses/ListCustomerActionStatuses',
      request,
      metadata || {},
      methodDescriptor_CustomerActionStatuses_ListCustomerActionStatuses,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListCustomerActionStatusesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListCustomerActionStatusesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CustomerActionStatusesPromiseClient.prototype.listCustomerActionStatuses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerActionStatuses/ListCustomerActionStatuses',
      request,
      metadata || {},
      methodDescriptor_CustomerActionStatuses_ListCustomerActionStatuses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetCustomerActionStatusRequest,
 *   !proto.dating.erp.api.v1.model.CustomerActionStatus>}
 */
const methodDescriptor_CustomerActionStatuses_GetCustomerActionStatus = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CustomerActionStatuses/GetCustomerActionStatus',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetCustomerActionStatusRequest,
  projects_erp_service_api_v1_model_customer_action_status_pb.CustomerActionStatus,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetCustomerActionStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_customer_action_status_pb.CustomerActionStatus.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetCustomerActionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.CustomerActionStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.CustomerActionStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CustomerActionStatusesClient.prototype.getCustomerActionStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerActionStatuses/GetCustomerActionStatus',
      request,
      metadata || {},
      methodDescriptor_CustomerActionStatuses_GetCustomerActionStatus,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetCustomerActionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.CustomerActionStatus>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CustomerActionStatusesPromiseClient.prototype.getCustomerActionStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerActionStatuses/GetCustomerActionStatus',
      request,
      metadata || {},
      methodDescriptor_CustomerActionStatuses_GetCustomerActionStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyCustomerActionStatusRequest,
 *   !proto.dating.erp.api.v1.model.CustomerActionStatus>}
 */
const methodDescriptor_CustomerActionStatuses_ApplyCustomerActionStatus = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.CustomerActionStatuses/ApplyCustomerActionStatus',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyCustomerActionStatusRequest,
  projects_erp_service_api_v1_model_customer_action_status_pb.CustomerActionStatus,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyCustomerActionStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_customer_action_status_pb.CustomerActionStatus.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyCustomerActionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.CustomerActionStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.CustomerActionStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.CustomerActionStatusesClient.prototype.applyCustomerActionStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerActionStatuses/ApplyCustomerActionStatus',
      request,
      metadata || {},
      methodDescriptor_CustomerActionStatuses_ApplyCustomerActionStatus,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyCustomerActionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.CustomerActionStatus>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.CustomerActionStatusesPromiseClient.prototype.applyCustomerActionStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.CustomerActionStatuses/ApplyCustomerActionStatus',
      request,
      metadata || {},
      methodDescriptor_CustomerActionStatuses_ApplyCustomerActionStatus);
};


module.exports = proto.dating.erp.api.v1.services;

