/**
 * @fileoverview gRPC-Web generated client stub for dating.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/trigger_instances.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var projects_erp_service_api_v1_model_trigger_instance_pb = require('./trigger_instance_pb.js')
const proto = {};
proto.dating = {};
proto.dating.erp = {};
proto.dating.erp.api = {};
proto.dating.erp.api.v1 = {};
proto.dating.erp.api.v1.services = require('./trigger_instances_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.TriggerInstancesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dating.erp.api.v1.services.TriggerInstancesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ListTriggerInstancesRequest,
 *   !proto.dating.erp.api.v1.services.ListTriggerInstancesResponse>}
 */
const methodDescriptor_TriggerInstances_ListTriggerInstances = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.TriggerInstances/ListTriggerInstances',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ListTriggerInstancesRequest,
  proto.dating.erp.api.v1.services.ListTriggerInstancesResponse,
  /**
   * @param {!proto.dating.erp.api.v1.services.ListTriggerInstancesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dating.erp.api.v1.services.ListTriggerInstancesResponse.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ListTriggerInstancesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.services.ListTriggerInstancesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.services.ListTriggerInstancesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.TriggerInstancesClient.prototype.listTriggerInstances =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.TriggerInstances/ListTriggerInstances',
      request,
      metadata || {},
      methodDescriptor_TriggerInstances_ListTriggerInstances,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ListTriggerInstancesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.services.ListTriggerInstancesResponse>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.TriggerInstancesPromiseClient.prototype.listTriggerInstances =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.TriggerInstances/ListTriggerInstances',
      request,
      metadata || {},
      methodDescriptor_TriggerInstances_ListTriggerInstances);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.GetTriggerInstanceRequest,
 *   !proto.dating.erp.api.v1.model.TriggerInstance>}
 */
const methodDescriptor_TriggerInstances_GetTriggerInstance = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.TriggerInstances/GetTriggerInstance',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.GetTriggerInstanceRequest,
  projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance,
  /**
   * @param {!proto.dating.erp.api.v1.services.GetTriggerInstanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.GetTriggerInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.TriggerInstance)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.TriggerInstance>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.TriggerInstancesClient.prototype.getTriggerInstance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.TriggerInstances/GetTriggerInstance',
      request,
      metadata || {},
      methodDescriptor_TriggerInstances_GetTriggerInstance,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.GetTriggerInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.TriggerInstance>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.TriggerInstancesPromiseClient.prototype.getTriggerInstance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.TriggerInstances/GetTriggerInstance',
      request,
      metadata || {},
      methodDescriptor_TriggerInstances_GetTriggerInstance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest,
 *   !proto.dating.erp.api.v1.model.TriggerInstance>}
 */
const methodDescriptor_TriggerInstances_ApplyTriggerInstance = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.TriggerInstances/ApplyTriggerInstance',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest,
  projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance,
  /**
   * @param {!proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.TriggerInstance)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.TriggerInstance>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.TriggerInstancesClient.prototype.applyTriggerInstance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.TriggerInstances/ApplyTriggerInstance',
      request,
      metadata || {},
      methodDescriptor_TriggerInstances_ApplyTriggerInstance,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ApplyTriggerInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.TriggerInstance>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.TriggerInstancesPromiseClient.prototype.applyTriggerInstance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.TriggerInstances/ApplyTriggerInstance',
      request,
      metadata || {},
      methodDescriptor_TriggerInstances_ApplyTriggerInstance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest,
 *   !proto.dating.erp.api.v1.model.TriggerInstance>}
 */
const methodDescriptor_TriggerInstances_ExecuteTriggerInstance = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.TriggerInstances/ExecuteTriggerInstance',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest,
  projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance,
  /**
   * @param {!proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_trigger_instance_pb.TriggerInstance.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dating.erp.api.v1.model.TriggerInstance)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dating.erp.api.v1.model.TriggerInstance>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.TriggerInstancesClient.prototype.executeTriggerInstance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.TriggerInstances/ExecuteTriggerInstance',
      request,
      metadata || {},
      methodDescriptor_TriggerInstances_ExecuteTriggerInstance,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.ExecuteTriggerInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dating.erp.api.v1.model.TriggerInstance>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.TriggerInstancesPromiseClient.prototype.executeTriggerInstance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.TriggerInstances/ExecuteTriggerInstance',
      request,
      metadata || {},
      methodDescriptor_TriggerInstances_ExecuteTriggerInstance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TriggerInstances_DeleteTriggerInstance = new grpc.web.MethodDescriptor(
  '/dating.erp.api.v1.services.TriggerInstances/DeleteTriggerInstance',
  grpc.web.MethodType.UNARY,
  proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dating.erp.api.v1.services.TriggerInstancesClient.prototype.deleteTriggerInstance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dating.erp.api.v1.services.TriggerInstances/DeleteTriggerInstance',
      request,
      metadata || {},
      methodDescriptor_TriggerInstances_DeleteTriggerInstance,
      callback);
};


/**
 * @param {!proto.dating.erp.api.v1.services.DeleteTriggerInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dating.erp.api.v1.services.TriggerInstancesPromiseClient.prototype.deleteTriggerInstance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dating.erp.api.v1.services.TriggerInstances/DeleteTriggerInstance',
      request,
      metadata || {},
      methodDescriptor_TriggerInstances_DeleteTriggerInstance);
};


module.exports = proto.dating.erp.api.v1.services;

