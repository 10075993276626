// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/legals'
import DocumentsAPI from 'api/documents'
import { getFileUrl } from 'api'

export const listLegals = createAsyncThunk('appLegals/listLegals', async (params, { signal }) => {
  const response = await API.listLegals(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.legalsList,
    totalRows: response.totalRows
  }
})

export const getLegals = createAsyncThunk('appLegals/getLegals', async (_, { signal }) => {
  const response = await API.listLegals()
  if (signal.aborted)
    return
  return response.legalsList
})

export const getLegal = createAsyncThunk('appLegals/getLegal', async (name, { dispatch, getState }) => {
  const loaded = (getState().legals.data ?? []).filter(e => e.name === name)[0]
  if (loaded)
    await dispatch(appLegals.actions.setSelected(loaded))
  return await API.getLegal(name)
})

export const addLegal = createAsyncThunk('appLegals/addLegal', async (instance) => {
  const updated = await API.applyLegal(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyLegal = createAsyncThunk('appLegals/modifyLegal', async (instance, { dispatch, getState }) => {
  const updated = await API.applyLegal(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appLegals.actions.setSelected(instance))
  await dispatch(listLegals(getState().legals.params))
  return instance
})

export const deleteLegal = createAsyncThunk('appLegals/deleteLegal', async (name) => {
  await API.deleteLegal(name)
  return name
})

const documentLocation2type = location => location.replace('/files', '')
const documentType2Location = type => `${type}/files`
const fileIsDocument = location => location.endsWith('/files')

export const getAllFiles = createAsyncThunk('appLegals/getAllFiles', async () => {
  const response = await DocumentsAPI.listDocuments({ location: "legals" })
  const result = { files: {} }
  for (let i = 0; i < response.length; i++) {
    const item = {
      ...response[i],
      url: getFileUrl(response[i].url)
    }
    
    if (fileIsDocument(item.location)) {
      const key = documentLocation2type(item.location)
      result.files[key] = (result.files[key] ?? []).concat([item])
    }
  }
  return result
})

export const getDocuments = createAsyncThunk('appLegals/getDocuments', async (typeName) => {
  const response = await DocumentsAPI.listDocuments({ location: documentType2Location(typeName) })
  const items = []
  for (let i = 0; i < response.length; i++) {
    const doc = {
      ...response[i],
      url: getFileUrl(response[i].url)
    }
    items.push(doc)
  }
  const result = {}
  result[typeName] = items
  return result
})

export const getDocumentByName = createAsyncThunk('appLegals/getDocumentByName', async (name) => {
  return await DocumentsAPI.getDocument(name)
})

export const addDocument = createAsyncThunk('appLegals/addDocument', async (document) => {
  const updated = await DocumentsAPI.applyDocument(document)
  document = {
    ...document,
    ...updated
  }
  return document
})

export const deleteDocument = createAsyncThunk('appLegals/deleteDocument', async ({ name, location }, { dispatch }) => {
  await DocumentsAPI.deleteDocument(name)
  await dispatch(getDocuments(documentLocation2type(location)))
  return name
})

export const appLegals = createSlice({
  name: 'appLegals',
  initialState: {
    data: [],
    dataAll: [],
    total: 1,
    params: {},
    files: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getLegals.fulfilled, (state, { payload }) => {
        state.dataAll = payload
      })
      .addCase(listLegals.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getLegal.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
      .addCase(getAllFiles.fulfilled, (state, { payload }) => {
        state.files = payload.files
      })
      .addCase(getDocuments.fulfilled, (state, { payload }) => {
        state.files = {
          ...state.files,
          ...payload
        }
      })
      .addCase(addDocument.fulfilled, (state, { payload }) => {
        const files = { ...state.files }
        const key = documentLocation2type(payload.location)
        files[key] = (files[key] ?? []).concat(payload)
        state.files = {
          ...files
        }
      })
  }
})

export default appLegals.reducer
